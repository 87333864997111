import {mapGetters} from 'vuex';

export default {
  name: 'match',

  data() {
    return {
      searchQuery: ''
    }
  },

  mounted() {
    if (!this.getUploadImportFileData()) {
      this.$router.push({name: 'ImportLegalDocumentUpload'})
    }
  },

  computed: {
    formFields() {
      return this.getUploadImportFileData()?.legalDocument?.formFields;
    },

    dbFieldsToMatch() {
      const forSelect = this.getUploadImportFileData()?.fieldsToMap;

      if (this.searchQuery) {
        return forSelect.filter(item => (
            this.searchQuery.toLowerCase().split(' ')
                .every(el => item.localFieldAlias.toLowerCase().includes(el))
        ));
      }

      return forSelect;
    },
  },

  methods: {
    ...mapGetters([
        'getUploadImportFileData'
    ]),

    selectField(fieldToMatch, formField) {
      this.$hideModals();
      this.search = '';

      formField['localField'] = fieldToMatch.localField;
      formField['localFieldAlias'] = fieldToMatch.localFieldAlias;
      formField['modelRelated'] = fieldToMatch.modelRelated;
    },

    confirmField(formField) {
      formField.isMapped = true;
      formField.isIgnored = false;
    },

    ignoreField(formField) {
      formField.isMapped = true;
      formField.isIgnored = true;
    },
  },
}